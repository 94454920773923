

















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import type -------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { TranslateResult } from 'vue-i18n';
// -------------------------------------------------------------------------------------------------

export type NavItem = {
  title: TranslateResult;
  hash: string;
  isSelected: boolean;
  to: any;
};

export default defineComponent({
  name: 'NavbarItem',
  props: {
    item: {
      type: Object as PropType<NavItem>,
      required: true
    }
  }
});
