






















// Import vendors ----------------------------------------------------------------------------------
import {
  ref,
  computed,
  defineComponent,
  watch,
  onUpdated,
  nextTick,
  Ref,
  PropType
} from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ExportationFrameWalkingComparison from '@/components/exportation/frames/walking/ExportationFrameWalkingComparison.vue';
import ExportationFrameRunningComparison from '@/components/exportation/frames/running/ExportationFrameRunningComparison.vue';
import ExportationFrameJumpingComparison from '@/components/exportation/frames/jumping/ExportationFrameJumpingComparison.vue';
// Import types ------------------------------------------------------------------------------------
import { Entity } from '@/plugins/podocore/helpers/repositories.helper';
import { Patient } from '@/plugins/podocore/repositories/patients.repository';
// Import utils ------------------------------------------------------------------------------------
import { useRehab } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export type TShowDoctorData = {
  email: boolean;
  phone: boolean;
};

export type TShowPatientData = {
  name: boolean;
  age: boolean;
  gender: boolean;
  height: boolean;
  weight: boolean;
};

export default defineComponent({
  name: 'ExportationViewer',
  components: {
    ExportationFrameWalkingComparison,
    ExportationFrameRunningComparison,
    ExportationFrameJumpingComparison
  },
  props: {
    scenario: {
      type: String,
      required: true
    },
    analysis: {
      type: Object,
      required: true
    },
    patient: {
      type: Object as PropType<Entity<Patient>>,
      required: true
    },
    headerInformations: {
      type: Object,
      required: true
    },
    mode: {
      type: String as PropType<'normal' | 'advanced'>,
      required: true
    },
    pending: {
      type: Boolean,
      required: true
    }
  },
  setup(properties) {
    const rIframe: Ref<any | null> = ref(null);

    const { getMode } = useRehab();

    const pageTotal = ref(0);
    const comments = ref('');
    const actions = ref('');

    const advancedMode = computed(() => properties.mode === 'advanced');

    function print() {
      if (rIframe.value?.exportApp) rIframe.value.exportApp.print();
    }

    function setComments(_comments: string) {
      comments.value = _comments;
    }
    function setActions(_actions: string) {
      actions.value = _actions;
    }

    nextTick(() => {
      setTimeout(() => {
        if (rIframe.value.exportApp) {
          pageTotal.value =
            rIframe.value.exportApp.$children[rIframe.value.exportApp.$children.length - 1].$children.length;
        }
      }, 500);
    });

    watch(
      properties,
      () => {
        nextTick(() => {
          nextTick(() => {
            if (rIframe.value.exportApp) {
              pageTotal.value =
                rIframe.value.exportApp.$children[
                  rIframe.value.exportApp.$children.length - 1
                ].$children.length;
            }
          });
        });
      },
      {
        deep: true,
        immediate: true
      }
    );

    onUpdated(() => {
      nextTick(() => {
        nextTick(() => {
          if (rIframe.value.exportApp) {
            pageTotal.value =
              rIframe.value.exportApp.$children[
                rIframe.value.exportApp.$children.length - 1
              ].$children.length;
          }
        });
      });
    });

    return {
      // References
      rIframe,
      // Values
      pageTotal,
      comments,
      actions,
      advancedMode,
      // Methods
      print,
      setComments,
      setActions,
      getMode
    };
  }
});
