







































































































































































































































































































































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onMounted, ref, toRef, toRefs } from '@vue/composition-api';
import { get } from 'lodash';
import { phone } from 'phone';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import helpers ----------------------------------------------------------------------------------
import { rounded } from '@/helpers/conversion.helper';
import { staticValues, TFootSizes } from '@/helpers/static-values.helper';
// Import utils ------------------------------------------------------------------------------------
import {
  useFormFieldAutoComplete,
  useFormFieldButtonToggle,
  useFormFieldDate,
  useFormFieldText,
  useFormObserver,
  cleanFieldValue,
  useForm
} from '@/utils/forms.utils';
import { useI18n, getCountries } from '@/utils/i18n.utils';
import { useUnit } from '@/utils/unit.utils';
// Import components -------------------------------------------------------------------------------
import DialogPatientPathology from '@/components/dialogs/DialogPatientPathology.vue';
import FormFieldButtonToggle from '@/components/forms/FormFieldButtonToggle.vue';
import FormFieldText from '@/components/forms/FormFieldText.vue';
import FormFieldTextPatientHeight from '@/components/forms/FormFieldTextPatientHeight.vue';
import FormFieldDate from '@/components/forms/FormFieldDate.vue';
import FormFieldPhone from '@/components/forms/FormFieldPhone.vue';
import FormFieldTextArea from '@/components/forms/FormFieldTextArea.vue';
import FormFieldAutoComplete from '@/components/forms/FormFieldAutoComplete.vue';
import FormFieldSelect from '@/components/forms/FormFieldSelect.vue';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { Entity } from '@/plugins/podocore/helpers/repositories.helper';
import type { Patient } from '@/plugins/podocore/repositories/patients.repository';
import type { ICountry } from '@digitsole/blackburn-iso-3166-dial';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientForm',
  props: {
    patient: {
      type: Object as PropType<Entity<Patient> | null>,
      default: null
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FormFieldSelect,
    DialogPatientPathology,
    FormFieldButtonToggle,
    FormFieldText,
    FormFieldTextPatientHeight,
    FormFieldDate,
    FormFieldPhone,
    FormFieldTextArea,
    FormFieldAutoComplete
  },
  setup(properties, { root, emit }) {
    const rObserver = useFormObserver();

    const { patient } = toRefs(properties);

    const busModule = usePodocoreModule('bus');

    const currentUnit = useUnit().currentUnit();

    const { dateFormat } = useI18n();

    const { footSizesStandards } = staticValues();

    const openedPanels = ref([0]);

    const healthyPatient = ref(false);

    const validPathology = computed(
      () =>
        !patient.value &&
        ((!healthyPatient.value && pathology.value) || (healthyPatient.value && !pathology.value))
    );

    const firstName = useFormFieldText<string>({
      key: 'firstName',
      value: '',
      rules: {
        required: true,
        min: 2,
        max: 50
      }
    });

    const lastName = useFormFieldText<string>({
      key: 'lastName',
      value: '',
      rules: {
        required: true,
        min: 2,
        max: 50
      }
    });

    const birthDate = useFormFieldDate({
      key: 'birthDate',
      value: '',
      rules: {
        required: true,
        birthDate: dateFormat.value
      },
      datePicker: {
        max: root.$moment().subtract(7, 'years').toISOString()
      }
    });

    const height = useFormFieldText<number>({
      key: 'height',
      value: null!,
      type: 'number',
      rules: {
        required: true,
        integer: currentUnit.isImperial.value ? false : true,
        min_value: currentUnit.isImperial.value ? 1 : 45,
        max_value: currentUnit.isImperial.value ? 8.84 : 270
      },
      forceType: Number
    });

    const weight = useFormFieldText<number>({
      key: 'weight',
      value: null!,
      type: 'number',
      rules: {
        required: true,
        min_value: currentUnit.isImperial.value ? 11.1 : 5,
        max_value: currentUnit.isImperial.value ? 550 : 250
      },
      forceType: Number
    });

    const footSize = useFormFieldAutoComplete<TFootSizes>({
      key: 'footSize',
      value: null!,
      items: footSizesStandards,
      rules: {
        required: true,
        integer: true,
        min_value: 10,
        max_value: 60
      }
    });

    const gender = useFormFieldButtonToggle<number>({
      key: 'gender',
      value: 1,
      buttons: [
        {
          value: 1,
          content: root.$t('commons.standards.men').toString()
        },
        {
          value: 2,
          content: root.$t('commons.standards.women').toString()
        }
      ],
      rules: {
        required: true,
        oneOf: [1, 2]
      }
    });

    const address = {
      street: useFormFieldText<string>({
        key: 'address.street',
        value: '',
        rules: {}
      }),
      zipCode: useFormFieldText<string>({
        key: 'address.zip-code',
        value: '',
        rules: {}
      }),
      city: useFormFieldText<string>({
        key: 'address.city',
        value: '',
        rules: {}
      }),
      state: useFormFieldText<string>({
        key: 'address.state',
        value: '',
        rules: {}
      }),
      country: useFormFieldAutoComplete<string, ICountry>({
        key: 'address.country',
        value: null!,
        items: getCountries(),
        rules: {}
      })
    };

    const contact = {
      email: useFormFieldText<string, null>({
        key: 'contact.email',
        value: '',
        defaultValue: null,
        rules: {
          email: true
        }
      }),
      phone: {
        dial: useFormFieldText<string>({
          key: 'contact.phone.dial',
          value: '1',
          rules: {
            required: true
          }
        })
      } as any
    };

    contact.phone.value = useFormFieldText<string>({
      key: 'contact.phone.value',
      value: '',
      rules: {
        phone: toRef(contact.phone.dial, 'value')
      }
    });

    const consultationReason = useFormFieldText<string>({
      key: 'consultation-reason',
      value: '',
      rules: {}
    });

    const background = useFormFieldText<string>({
      key: 'background',
      value: '',
      rules: {}
    });

    const pathology = ref<any>(null!);

    const { submit } = useForm(
      {
        firstName,
        lastName,
        birthDate,
        height,
        weight,
        footSize,
        gender,
        address,
        contact,
        consultationReason,
        background,
        pathology
      },
      rObserver,
      () => {
        if (validPathology.value || patient.value) emit('submit', getFields());
      }
    );

    function getFields() {
      const fields = {
        firstName: cleanFieldValue(firstName),
        lastName: cleanFieldValue(lastName),
        birthDate: root.$moment(cleanFieldValue(birthDate), dateFormat.value).format('YYYY-MM-DD'),
        height: cleanFieldValue(height),
        weight: cleanFieldValue(weight),
        footSize: cleanFieldValue(footSize),
        gender: cleanFieldValue(gender),

        address: {
          street: cleanFieldValue(address.street),
          zipCode: cleanFieldValue(address.zipCode),
          city: cleanFieldValue(address.city),
          state: cleanFieldValue(address.state),
          // TODO : fix typing
          country: cleanFieldValue(address.country as never)
        },

        contact: {
          email: cleanFieldValue(contact.email)
            ? cleanFieldValue(contact.email)!.toLowerCase()
            : cleanFieldValue(contact.email) || null,
          phone:
            cleanFieldValue(contact.phone.dial) && cleanFieldValue(contact.phone.value)
              ? '+' +
                (((cleanFieldValue(contact.phone.dial) as any) + cleanFieldValue(contact.phone.value)) as any)
              : null
        }
      };

      if (patient.value) {
        return {
          ...fields,
          consultationReason: get(patient.value, 'infos.consultationReason'),
          background: get(patient.value, 'infos.background'),
          pathologies: get(patient.value, 'infos.pathologies', [])
        };
      }

      return {
        ...fields,
        consultationReason: cleanFieldValue(consultationReason),
        background: cleanFieldValue(background),
        pathologies: pathology.value
          ? [
              {
                pathologyCuid: pathology.value.pathology.cuid,
                sinceDate: pathology.value.sinceDate
              }
            ]
          : []
      };
    }

    function setPatientPathology() {
      healthyPatient.value = false;
      busModule.publish(
        busModule.events.openDialog({
          id: 'patient-pathology',
          meta: {
            pathology: pathology.value,
            mode: 'add'
          }
        })
      );
    }

    function savePatientPathology(data: any) {
      pathology.value = {
        pathology: data.pathologyPatched.pathology,
        sinceDate: data.pathologyPatched.sinceDate
      };
    }

    function removePatientPathology() {
      pathology.value = null;
    }

    onMounted(() => {
      if (patient.value) {
        firstName.value = get(patient.value, 'infos.firstName');
        lastName.value = get(patient.value, 'infos.lastName');
        birthDate.value = (root.$moment(get(patient.value, 'infos.birthDate')) as any).format(
          dateFormat.value
        );
        height.value = rounded(get(patient.value, 'infos.height'), currentUnit.isImperial.value ? 1 : 0);
        weight.value = rounded(get(patient.value, 'infos.weight'), 0);
        footSize.value = get(patient.value, 'infos.footSize');
        gender.value = get(patient.value, 'infos.gender');

        address.street.value = get(patient.value, 'infos.address.street');
        address.zipCode.value = get(patient.value, 'infos.address.zipCode');
        address.city.value = get(patient.value, 'infos.address.city');
        address.state.value = get(patient.value, 'infos.address.state');
        address.country.value = get(patient.value, 'infos.address.country');

        contact.email.value = get(patient.value, 'infos.contact.email');

        const phoneNumber = phone(get(patient.value, 'infos.contact.phone'), { validateMobilePrefix: false });
        if (phoneNumber.phoneNumber && phoneNumber.countryCode) {
          contact.phone.dial.value = phoneNumber.countryCode.replace('+', '');
          contact.phone.value.value = phoneNumber.phoneNumber.replace(phoneNumber.countryCode, '');
        } else {
          contact.phone.dial.value = '1';
        }
      }
    });

    return {
      // References
      rObserver,
      // Form fields
      firstName,
      lastName,
      birthDate,
      height,
      weight,
      footSize,
      gender,
      address,
      contact,
      consultationReason,
      background,
      pathology,
      // Values
      openedPanels,
      healthyPatient,
      currentUnit,
      // Methods
      setPatientPathology,
      savePatientPathology,
      removePatientPathology,
      submit
    };
  }
});
