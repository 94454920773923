






































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, reactive } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ExportationViewerPage from '@/components/exportation/ExportationViewerPage.vue';
import ExportationFrameHeader from '@/components/exportation/frames/ExportationFrameHeader.vue';
import ExportationFrameComments from '@/components/exportation/frames/ExportationFrameComments.vue';
//
import ChartEvolutionRehabSide from '@/components/charts/evolution/rehab/ChartEvolutionRehabSide.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameJumpingTripleHopEvolution',
  components: {
    ExportationFrameHeader,
    ExportationViewerPage,
    ExportationFrameComments,
    //
    ChartEvolutionRehabSide
  },
  props: {
    analyses: {
      type: Object,
      required: true
    },
    advancedMode: {
      type: Boolean,
      required: true
    },
    pageTotal: {
      type: Number,
      required: true,
      default: 0
    },
    headerInformations: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const aggregates = computed(() => properties.analyses.data.aggregates);
    const manifest = computed(() => properties.analyses.analysisManifests);

    const unit = reactive({
      totaldistance: getUnit(EValueTypeUnit.TotalDistance),
      jumpheight: getUnit(EValueTypeUnit.JumpHeight)
    });

    return {
      aggregates,
      manifest,
      unit
    };
  }
});
