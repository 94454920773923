








































import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ExportationViewerPage from '@/components/exportation/ExportationViewerPage.vue';
import ExportationFrameHeader from '@/components/exportation/frames/ExportationFrameHeader.vue';
import ExportationFrameComments from '@/components/exportation/frames/ExportationFrameComments.vue';
// Charts
import ExportationFrameWalkingChartBanner from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartBanner.vue';
import ExportationFrameWalkingChartStrideLength from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartStrideLength.vue';
import ExportationFrameWalkingChartStanceTime from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartStanceTime.vue';
import ExportationFrameWalkingChartSwingTime from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartSwingTime.vue';
import ExportationFrameWalkingChartStrideDuration from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartStrideDuration.vue';
import ExportationFrameWalkingChartClearanceSteppage from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartClearanceSteppage.vue';
import ExportationFrameWalkingChartPropulsionRatio from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartPropulsionRatio.vue';
import ExportationFrameWalkingChartCircumduction from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartCircumduction.vue';
import ExportationFrameWalkingChartClearance from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartClearance.vue';
import ExportationFrameWalkingChartGaitLine from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartGaitLine.vue';
import ExportationFrameWalkingChartFootProgressionAngle from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartFootProgressionAngle.vue';
import ExportationFrameWalkingChartPronationAngles from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartPronationAngles.vue';

export default defineComponent({
  name: 'ExportationFrameWalkingCustom',
  components: {
    ExportationViewerPage,
    ExportationFrameHeader,
    //ExportationFrameComments,
    // Charts
    ExportationFrameWalkingChartBanner
    // ExportationFrameWalkingChartStrideLength,
    // ExportationFrameWalkingChartStanceTime,
    // ExportationFrameWalkingChartSwingTime,
    // ExportationFrameWalkingChartStrideDuration,
    // ExportationFrameWalkingChartClearanceSteppage,
    // ExportationFrameWalkingChartPropulsionRatio,
    // ExportationFrameWalkingChartCircumduction,
    // ExportationFrameWalkingChartClearance,
    // ExportationFrameWalkingChartGaitLine,
    // ExportationFrameWalkingChartFootProgressionAngle,
    // ExportationFrameWalkingChartPronationAngles
  },
  props: {
    analysis: {
      type: Object,
      required: true
    },
    customFilter: {
      type: Object,
      required: true
    },
    pageTotal: {
      type: Number,
      required: true,
      default: 0
    },
    headerInformations: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const aggregates = computed(() => properties.analysis.metrics.aggregates);
    const displayMetrics = computed(() => properties.analysis.display_metrics.aggregates);
    const norms = computed(() => properties.analysis.metrics.norms);
    const isDegradedActivity = computed(() => properties.analysis.analysis_validity === 1);

    const charts: any = {
      stanceTime: {
        component: ExportationFrameWalkingChartStanceTime,
        height: 250
      },
      swingTime: {
        component: ExportationFrameWalkingChartSwingTime,
        height: 250
      },
      strideDuration: {
        component: ExportationFrameWalkingChartStrideDuration,
        height: 150
      },
      strideLength: {
        component: ExportationFrameWalkingChartStrideLength,
        height: 250
      },
      steppageClearance: {
        component: ExportationFrameWalkingChartClearanceSteppage,
        height: 400
      },
      propulsionRate: {
        component: ExportationFrameWalkingChartPropulsionRatio,
        height: 350
      },
      pronationSupinationAngles: {
        component: ExportationFrameWalkingChartPronationAngles,
        props: { height: 450, fullMode: true },
        height: 680
      },
      gaitline: {
        component: ExportationFrameWalkingChartGaitLine,
        height: 350
      },
      comments: {
        component: ExportationFrameComments,
        height: 350
      }
    };

    const filters: any = computed(() => {
      const _filters = properties.customFilter.filters;
      if (!_filters.find((x: string) => x === 'comments')) _filters.push('comments');
      return _filters;
    });

    const pages: any = computed(() => {
      const pages = [{ pageIndex: 1, space: 400, remainingSpace: 400, charts: [] }];

      filters.value.forEach((filter: string, index: number) => {
        const currentFilter = charts[filter];
        if (currentFilter.height <= pages[pages.length - 1].remainingSpace) {
          pages[pages.length - 1].remainingSpace =
            pages[pages.length - 1].remainingSpace - currentFilter.height;
          (pages[pages.length - 1].charts as any).push(currentFilter);
        } else {
          pages.push({ pageIndex: pages.length + 1, space: 750, remainingSpace: 750, charts: [] });
          pages[pages.length - 1].remainingSpace - currentFilter.height;
          (pages[pages.length - 1].charts as any).push(currentFilter);
        }
      });

      return pages;
    });

    return {
      pages,
      aggregates,
      displayMetrics,
      norms,
      // Flags
      isDegradedActivity
    };
  }
});
