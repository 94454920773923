








// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartWalkingWidgetSymmetry from '@/components/charts/walking/widgets/ChartWalkingWidgetSymmetry.vue';
import ChartWalkingWidgetSpeed from '@/components/charts/walking/widgets/ChartWalkingWidgetSpeed.vue';
import ChartWalkingWidgetCadence from '@/components/charts/walking/widgets/ChartWalkingWidgetCadence.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingContainerGroupBanner',
  components: {
    ChartWalkingWidgetSymmetry,
    ChartWalkingWidgetSpeed,
    ChartWalkingWidgetCadence
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    degraded: {
      type: Boolean
    },
    simple: {
      type: Boolean
    }
  }
});
