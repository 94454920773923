






// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, PropType } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameTitle',
  props: {
    type: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    name: {
      type: String as PropType<string | null | undefined>,
      required: false
    }
  },
  setup(properties) {
    const isEvolution = computed(() => properties.mode === 'evolution');
    const title = computed(() => {
      if (properties.name) return properties.name;
      if (properties.type.startsWith('walking'))
        return i18n.tc(
          `commons.sentences.${isEvolution ? 'evolution-walk-analysis' : 'results-walk-analysis'}`
        );
      if (properties.type.startsWith('running'))
        return i18n.tc(
          `commons.sentences.${isEvolution ? 'evolution-run-analysis' : 'results-run-analysis'}`
        );
      if (properties.type.startsWith('jumping'))
        return i18n.tc(
          `commons.sentences.${isEvolution ? 'evolution-jump-analysis' : 'results-jump-analysis'}`
        );
      return 'N/A';
    });

    return {
      title
    };
  }
});
