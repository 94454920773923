














































































import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ExportationViewerPage from '@/components/exportation/ExportationViewerPage.vue';
import ExportationFrameHeader from '@/components/exportation/frames/ExportationFrameHeader.vue';
import ExportationFrameComments from '@/components/exportation/frames/ExportationFrameComments.vue';
// Charts
import ExportationFrameWalkingChartBanner from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartBanner.vue';
import ExportationFrameWalkingChartStrideLength from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartStrideLength.vue';
import ExportationFrameWalkingChartStanceTime from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartStanceTime.vue';
import ExportationFrameWalkingChartSwingTime from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartSwingTime.vue';
import ExportationFrameWalkingChartStrideDuration from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartStrideDuration.vue';
import ExportationFrameWalkingChartClearanceSteppage from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartClearanceSteppage.vue';
import ExportationFrameWalkingChartPropulsionRatio from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartPropulsionRatio.vue';
import ExportationFrameWalkingChartCircumduction from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartCircumduction.vue';
import ExportationFrameWalkingChartClearance from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartClearance.vue';
import ExportationFrameWalkingChartGaitLine from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartGaitLine.vue';
import ExportationFrameWalkingChartFootProgressionAngle from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartFootProgressionAngle.vue';
import ExportationFrameWalkingChartPronationAngles from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartPronationAngles.vue';

export default defineComponent({
  name: 'ExportationFrameWalkingReathleticism',
  components: {
    ExportationViewerPage,
    ExportationFrameHeader,
    ExportationFrameComments,
    // Charts
    ExportationFrameWalkingChartBanner,
    ExportationFrameWalkingChartStrideLength,
    ExportationFrameWalkingChartStanceTime,
    ExportationFrameWalkingChartSwingTime,
    ExportationFrameWalkingChartStrideDuration,
    ExportationFrameWalkingChartClearanceSteppage,
    ExportationFrameWalkingChartPropulsionRatio,
    ExportationFrameWalkingChartCircumduction,
    ExportationFrameWalkingChartClearance,
    ExportationFrameWalkingChartGaitLine,
    ExportationFrameWalkingChartFootProgressionAngle,
    ExportationFrameWalkingChartPronationAngles
  },
  props: {
    analysis: {
      type: Object,
      required: true
    },
    advancedMode: {
      type: Boolean,
      required: true
    },
    pageTotal: {
      type: Number,
      required: true,
      default: 0
    },
    headerInformations: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const aggregates = computed(() => properties.analysis.metrics.aggregates);
    const displayMetrics = computed(() => properties.analysis.display_metrics.aggregates);
    const norms = computed(() => properties.analysis.metrics.norms);
    const isDegradedActivity = computed(() => properties.analysis.analysis_validity === 1);

    return {
      aggregates,
      displayMetrics,
      norms,
      // Flags
      isDegradedActivity
    };
  }
});
