<template>
  <div class="document v-application">
    <!--region Header-->
    <ExportationViewerPage :total="pageTotal" page="1">
      <ExportationFrameHeader v-bind="{ headerInformations }" />

      <PatientResultSummary
        v-if="haveConditions"
        class="mt-10"
        disableEdition
        :disabledConditions="['walkingAids']"
      />
      <ChartRehabContainerBanner class="mb-5 mt-10" v-bind="{ aggregates, activeWidgets }" />

      <ExportationFrameJumpingChartTripleHopJumpProfileSummary
        v-if="mode === 'triple-hop'"
        v-bind="{ aggregates }"
        class="mb-5"
      />
      <ExportationFrameJumpingChartCMJJumpProfile
        v-if="mode === 'CMJ'"
        v-bind="{ aggregates, displayMetrics, displayTooltips }"
        class="mt-10"
      />
      <ExportationFrameJumpingChartSideHopCadenceEvolution
        v-if="mode === 'side-hop'"
        v-bind="{ aggregates, segments, displayMetrics, displayTooltips }"
        class="mb-5"
      />
    </ExportationViewerPage>
    <!--endregion-->

    <!--region Content-->
    <ExportationViewerPage :total="pageTotal" page="2">
      <ExportationFrameJumpingChartTripleHopJumpProfile
        v-if="mode === 'triple-hop'"
        v-bind="{ aggregates, displayMetrics, displayTooltips, segments }"
        :isAdvancedMode="advancedMode"
      />
      <template v-if="mode === 'CMJ'">
        <ExportationFrameJumpingChartCMJGeneralParameters
          v-bind="{ aggregates, displayMetrics, displayTooltips, segments }"
          :isAdvancedMode="advancedMode"
        />
        <ExportationFrameComments v-if="!advancedMode" :height="200" />
      </template>
      <template v-if="mode === 'single-hop'">
        <ExportationFrameJumpingChartSingleHopJumpProfile
          v-if="mode === 'single-hop'"
          v-bind="{ aggregates, displayMetrics, displayTooltips, segments }"
          class="mb-5"
        />
        <ExportationFrameJumpingChartSingleHopGeneralParameters
          v-if="!advancedMode"
          v-bind="{ aggregates, displayMetrics, displayTooltips, segments }"
          :isAdvancedMode="advancedMode"
        />
      </template>
      <template v-if="mode === 'side-hop'">
        <ExportationFrameJumpingChartSideHopJumpProfile
          v-bind="{ aggregates, displayMetrics, displayTooltips, segments }"
          class="mb-10"
        />
        <ExportationFrameJumpingChartSideHopGeneralParameters
          v-if="!advancedMode"
          v-bind="{ aggregates, displayMetrics, displayTooltips, segments }"
          page="2"
        />
      </template>
    </ExportationViewerPage>
    <ExportationViewerPage
      :total="pageTotal"
      page="3"
      v-if="
        (mode === 'CMJ' && advancedMode) ||
        mode === 'single-hop' ||
        mode === 'side-hop' ||
        mode === 'triple-hop'
      "
    >
      <template v-if="mode === 'triple-hop'">
        <ExportationFrameJumpingChartTripleHopGeneralParameters
          v-if="!advancedMode"
          v-bind="{ aggregates, displayMetrics, displayTooltips, segments }"
        />
        <ExportationFrameJumpingChartTripleHopGeneralParametersAdvanced
          v-else
          v-bind="{ aggregates, displayMetrics, displayTooltips, segments }"
          page="3"
        />
        <ExportationFrameComments v-if="!advancedMode" :height="200" />
      </template>
      <ExportationFrameJumpingChartSideHopGeneralParameters
        v-if="mode === 'side-hop' && advancedMode"
        v-bind="{ aggregates, displayMetrics, displayTooltips, segments }"
        page="3"
      />
      <ExportationFrameJumpingChartSingleHopGeneralParameters
        v-if="mode === 'single-hop' && advancedMode"
        v-bind="{ aggregates, displayMetrics, displayTooltips, segments }"
        :isAdvancedMode="advancedMode"
      />
      <ExportationFrameComments
        v-if="
          mode === 'CMJ' || (mode === 'single-hop' && !advancedMode) || (mode === 'side-hop' && !advancedMode)
        "
        v-bind="{ analysisComments: comments }"
        :height="300"
      />
    </ExportationViewerPage>
    <ExportationViewerPage
      v-if="
        (mode === 'triple-hop' && advancedMode) ||
        (mode === 'single-hop' && advancedMode) ||
        (mode === 'side-hop' && advancedMode)
      "
      :total="pageTotal"
      page="4"
    >
      <ExportationFrameComments
        v-if="mode === 'side-hop' || mode === 'single-hop'"
        v-bind="{ analysisComments: comments }"
        :height="300"
      />
      <template v-if="mode === 'triple-hop' && advancedMode">
        <ExportationFrameJumpingChartTripleHopGeneralParametersAdvanced
          v-bind="{ aggregates, displayMetrics, displayTooltips, segments }"
          page="4"
          class="mb-5"
        />
        <ExportationFrameComments v-bind="{ analysisComments: comments }" :height="250" class="mt-5" />
      </template>
    </ExportationViewerPage>
    <!--endregion-->
  </div>
</template>

<script>
import { defineComponent, computed, ref, provide } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ExportationViewerPage from '@/components/exportation/ExportationViewerPage.vue';
import ExportationFrameHeader from '@/components/exportation/frames/ExportationFrameHeader.vue';
import ExportationFrameComments from '@/components/exportation/frames/ExportationFrameComments.vue';
import PatientResultSummary from '@/components/patient/results/PatientResultSummary.vue';
// Charts
import ChartRehabContainerBanner from '@/components/charts/rehab/containers/ChartRehabContainerBanner.vue';
import ExportationFrameJumpingChartTripleHopJumpProfileSummary from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartTripleHopJumpProfileSummary.vue';
import ExportationFrameJumpingChartCMJJumpProfile from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartCMJJumpProfile.vue';
import ExportationFrameJumpingChartSideHopCadenceEvolution from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartSideHopCadenceEvolution.vue';
import ExportationFrameJumpingChartTripleHopJumpProfile from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartTripleHopJumpProfile.vue';
import ExportationFrameJumpingChartTripleHopGeneralParameters from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartTripleHopGeneralParameters.vue';
import ExportationFrameJumpingChartCMJGeneralParameters from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartCMJGeneralParameters.vue';
import ExportationFrameJumpingChartSingleHopGeneralParameters from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartSingleHopGeneralParameters.vue';
import ExportationFrameJumpingChartSideHopGeneralParameters from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartSideHopGeneralParameters.vue';
import ExportationFrameJumpingChartSideHopJumpProfile from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartSideHopJumpProfile.vue';
import ExportationFrameJumpingChartTripleHopGeneralParametersAdvanced from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartTripleHopGeneralParametersAdvanced.vue';
import ExportationFrameJumpingChartSingleHopJumpProfile from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartSingleHopJumpProfile.vue';
// Import utils ------------------------------------------------------------------------------------
import { useRehab } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameJumping',
  components: {
    PatientResultSummary,
    ExportationViewerPage,
    ExportationFrameHeader,
    ExportationFrameComments,
    // Charts
    ChartRehabContainerBanner,
    ExportationFrameJumpingChartTripleHopJumpProfileSummary,
    ExportationFrameJumpingChartCMJJumpProfile,
    ExportationFrameJumpingChartSideHopCadenceEvolution,
    ExportationFrameJumpingChartTripleHopJumpProfile,
    ExportationFrameJumpingChartTripleHopGeneralParameters,
    ExportationFrameJumpingChartCMJGeneralParameters,
    ExportationFrameJumpingChartSingleHopGeneralParameters,
    ExportationFrameJumpingChartSideHopJumpProfile,
    ExportationFrameJumpingChartSideHopGeneralParameters,
    ExportationFrameJumpingChartSingleHopJumpProfile,
    ExportationFrameJumpingChartTripleHopGeneralParametersAdvanced
  },
  props: {
    analysis: {
      type: Object,
      required: true
    },
    advancedMode: {
      type: Boolean,
      required: true
    },
    pageTotal: {
      type: Number,
      required: true,
      default: 0
    },
    headerInformations: {
      type: Object,
      required: true
    },
    comments: {
      type: Array,
      required: false
    },
    displayTooltips: {
      type: Boolean
    }
  },
  setup(properties) {
    provide('single-analysis', ref(properties.analysis));
    const { getMode, getActiveWidgets } = useRehab();

    const mode = getMode(properties.analysis._scenario.key);

    const activeWidgets = getActiveWidgets(mode);
    const aggregates = computed(() => properties.analysis.metrics.aggregates);
    const displayMetrics = computed(() => properties.analysis.display_metrics);
    const segments = computed(() => properties.analysis.metrics?.segment);
    const norms = computed(() => properties.analysis.metrics.norms);

    const haveConditions = computed(
      () =>
        !!properties.analysis.conditions.painList.length ||
        !!properties.analysis.conditions.walkingAidList.length ||
        !!properties.analysis.conditions.shoesType
    );

    return {
      mode,
      //
      activeWidgets,
      aggregates,
      displayMetrics,
      segments,
      norms,
      haveConditions
    };
  }
});
</script>

<style lang="scss" scoped>
.top-margin {
  margin-top: 30px;
  &-sm {
    margin-top: 20px;
  }
  &-lg {
    margin-top: 50px;
  }
}
.align-row {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 30px;
}
</style>
