











































// Import vendors ----------------------------------------------------------------------------------
import {
  provide,
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  watch,
  ref
} from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useSidebar } from '@/utils/sidebar.utils';
import { usePatient } from '@/utils/patient.utils';
import { useRoute } from '@/utils/router.utils';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
import PatientNavbar from '@/components/patient/PatientNavbar.vue';
import PatientDashboard from '@/components/patient/PatientDashboard.vue';
import PatientFile from '@/components/patient/PatientFile.vue';
import PractitionerAnalysis from '@/components/patient/PractitionerAnalysis.vue';
import PatientEvolution from '@/components/patient/PatientEvolution.vue';
import PatientMedias from '@/components/patient/PatientMedias.vue';
import DialogExportPDF from '@/components/dialogs/DialogExportPDF.vue';
import DialogAnalysisRename from '@/components/dialogs/DialogAnalysisRename.vue';
import DialogAnalysisDelete from '@/components/dialogs/DialogAnalysisDelete.vue';
import DialogPatientUpdate from '@/components/dialogs/DialogPatientUpdate.vue';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PagePatient',
  components: {
    AlertError,
    DialogAnalysisRename,
    DialogAnalysisDelete,
    DialogExportPDF,
    DialogPatientUpdate,
    PatientNavbar,
    PatientDashboard,
    PatientFile,
    PractitionerAnalysis,
    PatientEvolution,
    PatientMedias
  },
  setup(_, { root }) {
    const { hash, params } = useRoute();
    const requestModule = usePodocoreModule('request');
    const { data, error, isPending, request, cancel } = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/patients/${root.$route.params.cuid}`
    );
    provide('patient', ref(null));

    const { patientSidebarMiniVariant } = useSidebar();
    const { setPatient } = usePatient();
    const busModule = usePodocoreModule('bus');

    watch(isPending, (value) => {
      if (!value && !error.value) {
        setPatient(data);
        busModule.publish(busModule.events.patientLoaded({ patient: data.value }));
      }
    });

    watch(params, (value) => {
      request({ axios: { url: `${apiConfig.default}/patients/${value.cuid}` } });
    });

    onBeforeMount(() => {
      request();
    });

    onBeforeUnmount(() => {
      if (data.value?.cuid)
        busModule.publish(busModule.events.patientUnloaded({ patientCuid: data.value.cuid }));
      cancel();
    });

    busModule.useEventSubscriber(busModule.events.patientSwitched, () => {
      // Force new URL
      request({ axios: { url: `${apiConfig.default}/patients/${root.$route.params.cuid}` } });
    });

    busModule.useEventSubscriber(busModule.events.patientDeleted, () => {
      root.$router.push({ name: 'patients' });
    });

    const isPatientFilePage = computed(() => {
      return (
        !root.$route.path.includes('result') &&
        !root.$route.path.includes('evolution') &&
        !root.$route.path.includes('comparison')
      );
    });

    return {
      // Values
      error,
      patientSidebarMiniVariant,
      hash,
      // Flags
      isPending,
      isPatientFilePage
    };
  }
});
