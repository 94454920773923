<template>
  <div class="document">
    <!--region PAGE 1-->
    <ExportationViewerPage :total="pageTotal" page="1">
      <ExportationFrameHeader v-bind="{ headerInformations }" />

      <ExportationFrameRunningChartBanner
        v-bind="{ aggregates, displayMetrics: analysis.display_metrics, norms }"
        class="top-margin"
      />

      <ExportationFrameRunningChartSpeedCadenceDetailled
        v-if="detailledRunningMode && showDetails"
        class="top-margin-lg"
        v-bind="{ segments, exportSelectedSegments }"
      />
      <ExportationFrameRunningChartSpeedCadence v-else class="top-margin" v-bind="{ segments }" />
    </ExportationViewerPage>
    <!--endregion-->

    <!--region PAGE 2-->
    <ExportationViewerPage v-if="detailledRunningMode && showDetails" :total="pageTotal" page="2">
      <ChartRunningDetailledTable
        v-bind="{ segments, displayMetrics }"
        :first-value="exportSelectedSegments[0]"
        :second-value="exportSelectedSegments[1] || null"
        :is-comparison-page="false"
        :imperial="currentUnit().isImperial.value"
      />
    </ExportationViewerPage>

    <ExportationViewerPage v-else :total="pageTotal" page="2">
      <ExportationFrameRunningChartRunProfile
        v-bind="{ aggregates, displayMetrics, norms, displayTooltips }"
      />

      <ExportationFrameRunningChartPronationAngles
        v-bind="{ aggregates, displayMetrics, norms, displayTooltips }"
        class="top-margin-lg bottom-margin-lg"
        :advancedMode="true"
        :height="350"
      />
    </ExportationViewerPage>
    <!--Comments-->
    <ExportationViewerPage :total="pageTotal" :page="detailledRunningMode && showDetails ? 2 : 3">
      <ExportationFrameRunningChartStrikePattern
        v-if="!detailledRunningMode"
        v-bind="{ aggregates, displayMetrics, norms, displayTooltips }"
        class="top-margin-lg"
        :advancedMode="true"
      />
      <ExportationFrameComments v-bind="{ analysisComments: comments }" class="top-margin-lg" />
    </ExportationViewerPage>
    <!--Comments-->

    <!--endregion-->
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ExportationViewerPage from '@/components/exportation/ExportationViewerPage.vue';
import ExportationFrameHeader from '@/components/exportation/frames/ExportationFrameHeader.vue';
import ExportationFrameComments from '@/components/exportation/frames/ExportationFrameComments.vue';
// Charts
import ExportationFrameRunningChartBanner from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartBanner.vue';
import ExportationFrameRunningChartSpeedCadence from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartSpeedCadence.vue';
import ExportationFrameRunningChartRunProfile from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartRunProfile.vue';
import ExportationFrameRunningChartPronationAngles from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartPronationAngles.vue';
import ExportationFrameRunningChartStrikePattern from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartStrikePattern.vue';
import ExportationFrameRunningChartSpeedCadenceDetailled from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartSpeedCadenceDetailled.vue';
import ChartRunningDetailledTable from '@/components/charts/running/ChartRunningDetailledTable.vue';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameRunning',
  components: {
    ExportationViewerPage,
    ExportationFrameHeader,
    ExportationFrameComments,
    // Charts
    ExportationFrameRunningChartBanner,
    ExportationFrameRunningChartSpeedCadence,
    ExportationFrameRunningChartRunProfile,
    ExportationFrameRunningChartPronationAngles,
    ExportationFrameRunningChartStrikePattern,
    ExportationFrameRunningChartSpeedCadenceDetailled,
    ChartRunningDetailledTable
  },
  props: {
    analysis: {
      type: Object,
      required: true
    },
    advancedMode: {
      type: Boolean,
      required: true
    },
    pageTotal: {
      type: Number,
      required: true,
      default: 0
    },
    headerInformations: {
      type: Object,
      required: true
    },
    detailledRunningMode: {
      type: Boolean
    },
    showDetails: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    },
    exportSelectedSegments: {
      type: Array,
      required: false,
      default: () => []
    },
    comments: {
      type: Array,
      required: false
    }
  },
  setup(properties) {
    const { currentUnit } = useUnit();

    const aggregates = computed(() => properties.analysis.metrics.aggregates);
    const segments = computed(() => properties.analysis.metrics.segment);
    const displayMetrics = computed(() => properties.analysis.display_metrics.aggregates);
    const norms = computed(() => properties.analysis.metrics.norms);

    return {
      aggregates,
      segments,
      displayMetrics,
      norms,
      //
      currentUnit
    };
  }
});
</script>

<style lang="scss" scoped>
.top-margin {
  margin-top: 30px;
  &-sm {
    margin-top: 20px;
  }
  &-lg {
    margin-top: 50px;
  }
}
</style>
