<template>
  <ChartExportationContainer
    v-bind="{ valid, hideTitle, analysisDate, displayTooltips }"
    :title="$t('commons.standards.angle-pro-sup')"
    :tooltip="[{ type: 'angle-pro-sup', subType: 'walk' }]"
  >
    <template #content>
      <ChartWalkingPronationSupinationAnglesAdvanced
        v-if="advancedMode"
        v-bind="{ aggregates, norms, height, relative: false }"
      />
      <ChartWalkingGroupStepsAngles v-else v-bind="{ aggregates }" />
    </template>
  </ChartExportationContainer>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
import ChartWalkingGroupStepsAngles from '@/components/charts/walking/groups/ChartWalkingGroupStepsAngles';
import ChartWalkingPronationSupinationAnglesAdvanced from '@/components/charts/walking/ChartWalkingPronationSupinationAnglesAdvanced.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingChartPronationAngles',
  components: {
    ChartExportationContainer,
    ChartWalkingGroupStepsAngles,
    ChartWalkingPronationSupinationAnglesAdvanced
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    advancedMode: {
      type: Boolean
    },
    analysisDate: {
      type: String,
      required: false
    },
    fullMode: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    }
  },
  setup(properties) {
    const valid = computed(
      () =>
        properties.displayMetrics.walking_pronation_angle_FFO &&
        properties.displayMetrics.walking_pronation_angle_foot_in &&
        properties.displayMetrics.walking_pronation_angle_foot_out &&
        properties.displayMetrics.walking_pronation_angle_FFI &&
        properties.displayMetrics.walking_loading_time &&
        properties.displayMetrics.walking_propulsion_time &&
        properties.displayMetrics.walking_flatfoot_time
    );

    return {
      // Valid
      valid
    };
  }
});
</script>

<style lang="scss" scoped></style>
